/* src/components/common/Modal/DeleteAccountModal.module.css */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.warningIconContainer, .deleteIconContainer {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.warningIconContainer {
  background: rgba(255, 193, 7, 0.1);
}

.deleteIconContainer {
  background: rgba(220, 53, 69, 0.1);
}

.warningIcon {
  font-size: 40px;
  color: #ffc107;
}

.deleteIcon {
  font-size: 40px;
  color: #dc3545;
}

.warningTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #ffc107;
  margin-bottom: 1rem;
}

.warningContent {
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
}

.warningList {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.warningList li {
  margin-bottom: 0.5rem;
}

.alternativeAction {
  font-style: italic;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.contactLink {
  background: none;
  border: none;
  color: #00a86b;
  text-decoration: underline;
  cursor: pointer;
  padding: 0 0.25rem;
  font-style: normal;
}

.confirmText {
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.confirmationInput {
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

.confirmationInput:focus {
  outline: none;
  border-color: #dc3545;
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.cancelButton, .backButton {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancelButton:hover, .backButton:hover {
  background: rgba(255, 255, 255, 0.15);
}

.proceedButton, .deleteButton {
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.proceedButton {
  background: #ffc107;
  color: #000;
}

.proceedButton:hover {
  background: #e0a800;
}

.deleteButton {
  background: #dc3545;
  color: white;
}

.deleteButton:hover:not(:disabled) {
  background: #c82333;
}

.deleteButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}