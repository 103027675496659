/* src/components/TwoFactorSetup/TwoFactorSetup.module.css */
.container {
  background-color: #242424;
  border-radius: 12px;
  padding: 32px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 1.25rem;
  transition: color 0.2s;
}

.closeButton:hover {
  color: rgba(255, 255, 255, 0.9);
}

.progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.progressStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
}

.progressNumber {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-bottom: 8px;
}

.progressLabel {
  font-size: 0.8rem;
}

.progressLine {
  flex: 1;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 8px;
  margin-bottom: 24px;
}

.progressStep.active {
  color: #00a86b;
}

.progressStep.active .progressNumber {
  background-color: #00a86b;
  color: white;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: rgba(0, 168, 107, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.icon {
  font-size: 2rem;
  color: #00a86b;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 16px;
}

.description {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  line-height: 1.5;
  margin-bottom: 24px;
}

.instructions {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  margin-bottom: 24px;
}

.instructionsTitle {
  font-size: 1rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 8px;
}

.instructionsList {
  color: rgba(255, 255, 255, 0.7);
  margin-left: 16px;
  line-height: 1.6;
  padding-left: 8px;
}

.instructionsList li {
  margin-bottom: 8px;
}

.button {
  background-color: #00a86b;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  max-width: 200px;
}

.button:hover:not(:disabled) {
  background-color: #008f5d;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.buttonSecondary {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.buttonSecondary:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.15);
}

.buttons {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
}

.qrCodeContainer {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.qrCode {
  max-width: 200px;
  max-height: 200px;
}

.qrCodePlaceholder {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}

.secretKeyContainer {
  width: 100%;
  margin-bottom: 24px;
}

.secretKeyLabel {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 8px;
  text-align: center;
}

.secretKeyWrapper {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.secretKey {
  font-family: monospace;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 1px;
}

.copyButton {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: color 0.2s;
  font-size: 1rem;
}

.copyButton:hover {
  color: rgba(255, 255, 255, 0.9);
}

.verificationContainer {
  width: 100%;
  max-width: 200px;
  margin-bottom: 24px;
}

.verificationInput {
  width: 100%;
  padding: 12px;
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 4px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.9);
}

.verificationInput:focus {
  outline: none;
  border-color: #00a86b;
}

.error {
  background: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.3);
  color: #dc3545;
  padding: 12px;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 16px;
  font-size: 0.9rem;
  text-align: center;
}