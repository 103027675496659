:root {
  /* ===========================
     Core Colors
     =========================== */
  --primary-bg: #121212;
  --secondary-bg: #1f1f1f;
  --tertiary-bg: #2a2a2a;
  --footer-bg: #1a1a1a;

  /* ===========================
     Text Colors
     =========================== */
  --text-color: #f1f1f1;
  --text-color-light: #bdbdbd;
  --text-color-lighter: #888888;
  --text-color-dark: #333333;
  --footer-text-color: #ddd;
  --footer-link-color: #aaa;

  /* ===========================
     Brand & Link Colors
     =========================== */
  --link-color: #00aaff;
  --link-color-hover: #0088cc;
  --brand-primary: #00aaff;      /* Alternate references to #3b82f6 in code */
  --brand-secondary: #0088cc;    /* Or #2563eb in some hero sections */
  --brand-accent: #00d4ff;
  --brand-green: #00a86b;        /* Used widely for "active" states, e.g. sidebar, etc. */
  --brand-green-hover: #008f5d;  /* Hover state for brand-green */

  /* ===========================
     Additional Blues (often seen)
     =========================== */
  --primary-blue: #3b82f6;
  --accent-blue: #60a5fa;
  --hover-blue: #2563eb;         /* E.g., used as :hover for primary-blue */

  /* ===========================
     Button Colors
     =========================== */
  --button-bg: #3498db;
  --button-bg-hover: #2980b9;
  --button-primary: #00aaff;       /* Ties to link-color or brand-primary */
  --button-primary-hover: #0088cc; 
  --button-secondary: #2c2c2c;
  --button-secondary-hover: #3c3c3c;
  --button-success: #27ae60;
  --button-success-hover: #219a52;
  --button-danger: #e74c3c;
  --button-danger-hover: #c0392b;

  /* ===========================
     Status Colors
     =========================== */
  --success-color: #27ae60;  /* legacy "green" for success */
  --warning-color: #f1c40f;  /* used in original snippet */
  --error-color: #e74c3c;    /* also used for "danger" in some code */
  --info-color: #3498db;

  /* Extended status/connection states (from code references) */
  --status-online: #27ae60;
  --status-away: #f1c40f;
  --status-busy: #e74c3c;
  --status-offline: #95a5a6;

  /* Newer references from code (nothing left out) */
  --status-connected: #4caf50;
  --status-connecting: #ff9800;
  --status-disconnected: #f44336;
  --danger-color: #dc3545;         /* Also used for "deleteButton" background */
  --danger-hover: #c82333;         /* Hover color for that button */
  --retry-color: #ff5722;          /* Retry button background */
  --retry-hover-color: #e64a19;    /* Retry hover color */
  --reconnecting-color: #ffa500;   /* e.g., chatbox reconnecting messages */
  --warning-color-alt: #ffc107;    /* Another warning variant used in some code */
  --error-text-alt: #ff6b6b;       /* Text color for certain error messages */

  /* ===========================
     Message / Chat Colors
     =========================== */
  --message-bg: transparent;       /* Base for message bubbles */
  --user-message-bg: #0b93f6;
  --assistant-message-bg: #2c2c2c;
  --task-message-bg: #f1c40f;
  --system-message-bg: #e0e0e0;
  --completion-message-bg: #4a4a4a;
  --error-message-bg: #e74c3c;     /* For error bubble backgrounds */
  --error-text-color: #ffffff;     /* text color on error backgrounds */

  /* ===========================
     UI Component Colors
     =========================== */
  --chatbox-bg: #1e1e1e;
  --input-bg: #2b2b2b;
  --border-color: #444444;
  --divider-color: rgba(255, 255, 255, 0.1);
  --selection-bg: #555555;
  --selection-color: #ffffff;
  --card-bg: #1f1f1f;
  --card-border: rgba(255, 255, 255, 0.1);
  
  /* ===========================
     Gradients
     =========================== */
  --gradient-primary: linear-gradient(135deg, var(--brand-primary) 0%, var(--brand-secondary) 100%);
  --gradient-dark: linear-gradient(145deg, var(--secondary-bg) 0%, var(--primary-bg) 100%);
  --gradient-card: linear-gradient(145deg, rgba(31, 31, 31, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%);
  --gradient-button: linear-gradient(135deg, var(--button-primary) 0%, var(--button-primary-hover) 100%);

  /* ===========================
     Shadows
     =========================== */
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.12);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.15);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.2);
  --shadow-xl: 0 20px 25px rgba(0, 0, 0, 0.2);
  --card-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  --card-hover-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  --button-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  /* ===========================
     Overlays and Backdrops
     =========================== */
  --overlay-dark: rgba(0, 0, 0, 0.5);
  --overlay-light: rgba(255, 255, 255, 0.05);
  --overlay-medium: rgba(0, 0, 0, 0.3);
  --backdrop-blur: blur(8px);

  /* ===========================
     Typography
     =========================== */
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --font-family-mono: 'Consolas', 'Monaco', monospace;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* ===========================
     Font Sizes
     =========================== */
  --font-size-xs: 0.75rem;   /* 12px */
  --font-size-sm: 0.875rem;  /* 14px */
  --font-size-md: 1rem;      /* 16px */
  --font-size-lg: 1.125rem;  /* 18px */
  --font-size-xl: 1.25rem;   /* 20px */
  --font-size-2xl: 1.5rem;   /* 24px */
  --font-size-3xl: 2rem;     /* 32px */
  --font-size-4xl: 2.5rem;   /* 40px */
  --font-size-5xl: 3rem;     /* 48px */

  /* ===========================
     Line Heights
     =========================== */
  --line-height-tight: 1.2;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.75;
  --line-height-loose: 2;

  /* ===========================
     Spacing
     =========================== */
  --space-xs: 0.25rem;    /* 4px */
  --space-sm: 0.5rem;     /* 8px */
  --space-md: 1rem;       /* 16px */
  --space-lg: 1.5rem;     /* 24px */
  --space-xl: 2rem;       /* 32px */
  --space-2xl: 3rem;      /* 48px */
  --space-3xl: 4rem;      /* 64px */

  /* ===========================
     Border Radius
     =========================== */
  --radius-xs: 0.125rem;  /* 2px */
  --radius-sm: 0.25rem;   /* 4px */
  --radius-md: 0.5rem;    /* 8px */
  --radius-lg: 1rem;      /* 16px */
  --radius-xl: 1.5rem;    /* 24px */
  --radius-full: 9999px;

  /* ===========================
     Z-index Scale
     =========================== */
  --z-negative: -1;
  --z-elevate: 1;
  --z-sticky: 100;
  --z-drawer: 200;
  --z-dropdown: 300;
  --z-modal: 400;
  --z-popover: 500;
  --z-tooltip: 600;
  --z-toast: 700;
  --z-max: 9999;

  /* Additional Z-Index Variables */
  --z-above: 1000;
  --z-below: 0;

  /* ===========================
     Transitions & Animations
     =========================== */
  --transition-fast: 0.15s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;
  --transition-bounce: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  --animation-fast: 0.2s;
  --animation-normal: 0.3s;
  --animation-slow: 0.5s;

  /* ===========================
     Interactive States
     =========================== */
  --hover-transition: all 0.3s ease;
  --hover-scale: 1.02;
  --hover-brightness: 1.1;
  --active-scale: 0.98;
  --focus-ring: 0 0 0 2px var(--link-color);
  --focus-ring-offset: 2px;

  /* ===========================
     Layout
     =========================== */
  --header-height: 4rem;
  --footer-height: 3rem;
  --sidebar-width: 280px;
  --container-max-width: 1200px;
  --modal-width-sm: 400px;
  --modal-width-md: 600px;
  --modal-width-lg: 800px;

  /* ===========================
     Dropdown Styles
     =========================== */
  --dropdown-bg: #1f2937;
  --dropdown-border-color: rgba(255, 255, 255, 0.1);
  --dropdown-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  --dropdown-width: 250px;
  --dropdown-max-height: 200px;

  /* ===========================
     Connection States (Already had some, extended above)
     =========================== */
  --reconnecting-color: #ffa500;  /* Specifically used for chatbox reconnecting */

  /* ===========================
     Error Message Styles
     =========================== */
  --error-message-bg-light: rgba(255, 0, 0, 0.1);
  --error-message-border: rgba(255, 0, 0, 0.3);
  --error-message-text: #ff6b6b; /* alt text color for errors */

  /* ===========================
     Chatbox Styles
     =========================== */
  --chatbox-input-bg: #2b2b2b;
  --chatbox-border-color: #444444;
  --chatbox-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.2);
  --chatbox-max-width: 800px;

  /* ===========================
     Sidebar Styles
     =========================== */
  --sidebar-bg: #1a1a1a;
  --sidebar-border-right: rgba(255, 255, 255, 0.1);
  --sidebar-hover-bg: rgba(255, 255, 255, 0.1);
  --sidebar-active-bg: rgba(0, 168, 107, 0.15);
  --sidebar-active-color: #00a86b;

  /* ===========================
     Button Styles
     =========================== */
  --button-padding: 0.5rem 1rem;
  --button-border-radius: 4px;
  --button-transition: all 0.3s ease;
  --button-hover-opacity: 0.9;

  --button-connected-bg: var(--success-color);
  --button-connected-text: #ffffff;
  --button-disconnected-bg: var(--secondary-bg);
  --button-disconnected-text: var(--text-color);

  /* ===========================
     Spinner and Checkmark
     =========================== */
  --spinner-color: var(--link-color);
  --checkmark-color: var(--success-color);
  --spinner-animation-duration: 1s;

  /* ===========================
     Responsive Breakpoints
     =========================== */
  --breakpoint-mobile: 480px;
  --breakpoint-tablet: 768px;
  --breakpoint-desktop: 1024px;

  /* ===========================
     Specific Button Colors (Where used)
     =========================== */
  --retry-color: #ff5722;
  --retry-hover-color: #e64a19;
}
