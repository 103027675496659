/* src/components/common/Modal/Enable2FAModal.module.css */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal {
  max-width: 500px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  text-align: center;
}

.iconContainer {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(0, 168, 107, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.icon {
  font-size: 40px;
  color: #00a86b;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.description {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
}

.progressBar::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.progressStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.85rem;
  font-weight: 500;
  position: relative;
  z-index: 1;
  padding-top: 30px;
  flex: 1;
  text-align: center;
}

.progressStep::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  position: absolute;
  top: 10px;
}

.progressStep.active {
  color: #00a86b;
}

.progressStep.active::before {
  background-color: #00a86b;
  border-color: rgba(0, 168, 107, 0.3);
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

.step {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  text-align: left;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.stepNumber {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(0, 168, 107, 0.2);
  color: #00a86b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  font-weight: 600;
  flex-shrink: 0;
}

.qrCodeContainer {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrCode {
  max-width: 100%;
  max-height: 100%;
}

.qrCodePlaceholder {
  color: #333;
  font-size: 0.9rem;
  text-align: center;
}

.secretKeyContainer {
  margin-bottom: 1.5rem;
  width: 100%;
}

.secretKeyLabel {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
}

.secretKeyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 0.75rem;
  gap: 0.5rem;
}

.secretKey {
  font-family: monospace;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.9);
}

.copyButton {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyButton:hover {
  color: rgba(255, 255, 255, 0.9);
}

.verificationContainer {
  width: 100%;
  max-width: 200px;
  margin-bottom: 1.5rem;
}

.verificationInput {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
  text-align: center;
  letter-spacing: 4px;
  padding: 0.75rem;
  transition: border-color 0.2s;
}

.verificationInput:focus {
  outline: none;
  border-color: #00a86b;
}

.actionButton {
  background-color: #00a86b;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 150px;
}

.actionButton:hover:not(:disabled) {
  background-color: #008f5d;
}

.actionButton:disabled {
  background-color: rgba(0, 168, 107, 0.5);
  cursor: not-allowed;
}
