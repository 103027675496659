/* src/pages/DashboardPage/Dashboard/Sidebar/Sidebar.module.css */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  height: 100vh;
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1000;
  transition: width 0.3s ease, transform 0.3s ease;
}

/* Keep sidebar visible even in initial view */
.initialView {
  /* No transform or opacity changes to keep it visible */
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.soloButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  cursor: pointer;
  gap: 12px;
}

.smallLogo {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.soloText {
  flex: 1;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
}

.newChatIcon {
  font-size: 0.9rem;
  opacity: 0.7;
}

/* User Section */
.userSection {
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(to top, rgba(255, 255, 255, 0.03), transparent);
  position: relative;
  margin-top: auto; /* Push to bottom */
}

.userInfo {
  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  text-align: left;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.userInfo:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.userInitials {
  background: linear-gradient(135deg, #00a86b, #008f5d);
  color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-weight: 600;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userEmail {
  flex: 1;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userMenu {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 16px;
  right: 16px;
  background-color: #252525;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  margin-bottom: 8px;
  padding: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.2s ease;
  transform-origin: bottom;
}

.userMenuOpen {
  display: block;
}

.menuIcon {
  margin-right: 12px;
  font-size: 1rem;
  opacity: 0.8;
}

.userMenuItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.userMenuItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.userPlan {
  font-size: 0.8rem;
  color: #00a86b;
  margin: 8px 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 500;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    width: 260px;
  }
  
  .sidebar.sidebarOpen {
    transform: translateX(0);
  }
}

/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}