/* src/pages/DashboardPage/Dashboard/ChatBox/ChatBox.module.css */

/* Chatbox base styles - default is bottom positioning */
.chatboxContainer {
  position: fixed; /* Keep fixed for bottom positioning */
  bottom: 0;
  left: calc(50% + 140px); /* 280px/2 = 140px to offset from sidebar */
  transform: translateX(-50%);
  width: calc(100% - 280px - 40px); /* Subtract sidebar width and add some padding */
  max-width: 800px;
  padding: 0 20px 15px 20px; /* Keep left/right/bottom padding, but set top to 0 */
  z-index: 1000;
  background-color: #121212; /* Match exact background color */
  margin: 0 auto;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1); /* Use a sophisticated easing */
}

/* Initial state for new visitors - centered in main content area */
.initialState {
  position: fixed; /* Use fixed instead of absolute for consistent positioning */
  bottom: auto;
  top: 50%;
  left: calc(50% + 140px); /* EXACT SAME as normal chatbox for consistent centering */
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
  width: calc(100% - 280px - 40px); /* EXACT SAME as normal chatbox */
  max-width: 800px;
  padding: 15px 20px;
  background: none; /* Keep background transparent for initial state */
  padding-top: 15px; /* Reset padding for initial state */
}

/* Welcome header for initial state */
.welcomeHeader {
  text-align: center;
  margin-bottom: 20px;
}

.welcomeTitle {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #00a86b;
}

.welcomeSubtitle {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

/* Add collapsed sidebar state */
.sidebarCollapsed ~ .chatboxContainer {
  left: calc(50% + 36px); /* 72px/2 = 36px when sidebar is collapsed */
  width: calc(100% - 72px - 40px); /* Subtract collapsed sidebar width */
}

/* Update mobile styles */
@media (max-width: 768px) {
  .chatboxContainer {
    left: 50%; /* Reset to center when sidebar is hidden */
    width: 95%; /* Full width minus margins */
    padding: 10px 15px;
  }
  
  .initialState {
    left: 50%; /* Center in the screen on mobile */
    width: 95%;
    padding: 10px 15px;
  }
}

/* Input Area */
.chatboxInputArea {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
}

/* Attachment and Submit Button Styles */
.chatboxAttachmentButton,
.chatboxSubmitButton {
  background: none;
  border: none;
  color: #bbbbbb;
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 10px;
  transition: color 0.3s, transform 0.2s;
}

.chatboxAttachmentButton:hover,
.chatboxSubmitButton:hover {
  color: var(--link-color, #00a86b);
  transform: scale(1.1);
}

/* Textarea Styling */
.chatboxTextarea {
  flex: 1;
  resize: none;
  border: none;
  padding: 10px;
  font-size: 1rem;
  background-color: transparent;
  color: var(--text-color, #fff);
  max-height: 150px;
  overflow-y: auto;
  outline: none; /* Remove default outline */
  line-height: 1.5;
  transition: height 0.2s ease;
  overflow-wrap: break-word; /* Ensures text wraps within textarea */
  white-space: normal;
}

/* Remove blue outline on focus for the textarea */
.chatboxTextarea:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: none; /* Remove any potential box shadow on focus */
  border: none; /* Ensure no border appears */
}

/* Placeholder Text Color */
.chatboxTextarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}

/* Error Message */
.chatboxError {
  color: #e74c3c; /* Matches --error-message-bg */
  margin-top: 10px;
  background: rgba(231, 76, 60, 0.1);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.retryButton {
  background-color: rgba(231, 76, 60, 0.2);
  color: #e74c3c;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.2s ease;
}

.retryButton:hover {
  background-color: rgba(231, 76, 60, 0.3);
}

/* Locked State Styling */
.locked {
  opacity: 0.8; /* Slightly dimmed to indicate lock */
  cursor: not-allowed;
}

/* Disabled button state */
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}