/* src/components/Settings2FA/Settings2FA.module.css */
.container {
  background-color: #242424;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon {
  font-size: 1.5rem;
  color: #00a86b;
}

.headerText {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
}

.subtitle {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 4px 0 0 0;
}

.headerRight {
  display: flex;
  align-items: center;
}

.enabledStatus,
.disabledStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
  font-weight: 500;
}

.enabledStatus {
  background-color: rgba(0, 168, 107, 0.1);
  color: #00a86b;
}

.disabledStatus {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}

.enabledIcon {
  color: #00a86b;
}

.disabledIcon {
  color: rgba(255, 255, 255, 0.7);
}

.description {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  margin-bottom: 24px;
}

.button {
  background-color: #00a86b;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button:hover:not(:disabled) {
  background-color: #008f5d;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.disableButton {
  background-color: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.3);
  color: #dc3545;
}

.disableButton:hover {
  background-color: rgba(220, 53, 69, 0.2);
}

.buttonSecondary {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.buttonSecondary:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.15);
}

.inputContainer {
  width: 100%;
  max-width: 200px;
  margin: 0 auto 24px;
}

.verificationInput {
  width: 100%;
  padding: 12px;
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 4px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.9);
}

.verificationInput:focus {
  outline: none;
  border-color: #00a86b;
}

.error {
  background: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.3);
  color: #dc3545;
  padding: 12px;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 16px;
  font-size: 0.9rem;
  text-align: center;
}

.buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
}