/* src/components/common/Modal/ExportDataModal.module.css */
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.icon {
  font-size: 3rem;
  color: #00a86b;
}

.description {
  text-align: center;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

.sectionTitle {
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
}

.formatOptions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.formatOption {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  transition: background-color 0.2s;
}

.formatOption:hover {
  background: rgba(255, 255, 255, 0.08);
}

.formatOption.selected {
  background: rgba(0, 168, 107, 0.1);
  border: 1px solid rgba(0, 168, 107, 0.3);
}

.formatIconContainer {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.formatIcon {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
}

.formatDetails {
  flex: 1;
}

.formatName {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.25rem;
}

.formatDescription {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.6);
}

.dateRangeOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.dateRangeOption {
  padding: 0.75rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dateRangeOption:hover {
  background: rgba(255, 255, 255, 0.08);
}

.dateRangeOption.selected {
  background: rgba(0, 168, 107, 0.1);
  color: #00a86b;
  border: 1px solid rgba(0, 168, 107, 0.3);
}

.customDateRange {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.dateInput {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dateInput label {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
}

.dateInput input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  transition: border-color 0.2s;
}

.dateInput input:focus {
  outline: none;
  border-color: #00a86b;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.cancelButton {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancelButton:hover {
  background: rgba(255, 255, 255, 0.15);
}

.exportButton {
  background: #00a86b;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.exportButton:hover:not(:disabled) {
  background: #008f5d;
}

.exportButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}