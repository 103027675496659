/* MessageGroup.module.css */

/* Message Wrapper and Container */
.messageWrapper {
  margin-bottom: 15px;
  position: relative;
}

.messageGroupContainer {
  position: relative;
  min-height: 0; /* Remove the minimum height */
}

.message {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 10px;
  position: relative; /* Add position relative */
}

.messageUser {
  align-self: flex-end;
}

.messageAssistant {
  align-self: flex-start;
  transition: margin-top 0.2s ease;
  margin-top: 0; /* Reset default margin */
}


/* Message Text Styling */
.messageText {
  padding: 12px 16px;
  border-radius: 16px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  line-height: 1.5;
  max-width: 100%;
  box-sizing: border-box;
}

/* Message Types */
.messageUser .messageText {
  background-color: rgb(59, 130, 246);
  color: white;
  border-bottom-right-radius: 4px;
}

.messageAssistant .messageText {
  background-color: #262626;
  color: #e5e7eb;
  border-bottom-left-radius: 4px;
}

/* Markdown Content */
.markdownContent {
  width: 100%;
}

.markdownContent h1,
.markdownContent h2,
.markdownContent h3,
.markdownContent h4,
.markdownContent h5,
.markdownContent h6 {
  color: inherit;
  margin: 0.5em 0;
  font-weight: 600;
}

.markdownContent p {
  margin: 0.5em 0;
}

.markdownContent ul,
.markdownContent ol {
  margin: 0.5em 0;
  padding-left: 1.5em;
}

.markdownContent li {
  margin: 0.25em 0;
}

.markdownContent code {
  background-color: #2d2d2d;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', monospace;
  color: #e6e6e6;
}

.markdownContent pre {
  background-color: #2d2d2d;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  margin: 0.5em 0;
}

.markdownContent pre code {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

.markdownContent blockquote {
  border-left: 4px solid #555;
  padding-left: 10px;
  margin: 1em 0;
  color: #aaa;
}

/* Timestamps */
.messageTimestamp {
  font-size: 0.7rem;
  margin-top: 4px;
}

.messageUser .messageTimestamp {
  color: rgba(255, 255, 255, 0.7);
}

.messageAssistant .messageTimestamp {
  color: rgb(107, 114, 128);
}

/* Processing Dropdown */
.dropdownContainer {
  margin-left: 20px;
  margin-top: 10px;
  position: relative;
}

.dropdownSummary {
  cursor: pointer;
  font-size: 0.9rem;
  user-select: none;
  list-style: none;
  color: rgb(59, 130, 246);
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgb(243, 244, 246);
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
  transition: background-color 0.2s ease;
}

.dropdownSummary:hover {
  background-color: rgb(229, 231, 235);
}

.dropdownIcon {
  display: inline-flex;
  transform: rotate(0deg);
  transition: transform 0.2s ease;
  font-size: 12px;
}

.dropdownIcon.open {
  transform: rotate(180deg);
}

.processingSteps {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 4px;
  padding: 8px;
  background-color: #262626;
  border: 1px solid #404040;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 10;
  min-width: 250px;
  max-height: 300px;
  overflow-y: auto;
}

.processingStep {
  padding: 8px;
  border-bottom: 1px solid #404040;
}

.processingStep:last-child {
  border-bottom: none;
}

.stepText {
  font-size: 0.9rem;
  color: #e5e7eb;
  line-height: 1.4;
}

.stepTimestamp {
  font-size: 0.75rem;
  color: #9ca3af;
  margin-top: 4px;
}

/* Message Group Container with open dropdown */
.messageGroupContainer:has(.dropdownOpen) + .messageAssistant {
  margin-top: 320px; /* Adjust based on your dropdown height */
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .messageText {
    max-width: 90%;
  }
  
  .processingSteps {
    min-width: 200px;
  }
}

/* Accessibility */
.dropdownSummary:focus {
  outline: 2px solid rgb(59, 130, 246);
  outline-offset: 2px;
}

.processingSteps:focus-within {
  outline: 2px solid rgb(59, 130, 246);
  outline-offset: 2px;
}

/* Add at the bottom of MessageGroup.module.css */

/* Icon row and button styling */
.iconRow {
  display: flex;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.iconButton {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.iconButton:hover {
  background-color: #f2f2f2;
  color: #333;
}