/* src/components/common/Modal/DeleteConversationModal.module.css */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.iconContainer {
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.icon {
  color: #dc3545;
  font-size: 28px;
}

.title {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.95);
}

.note {
  display: block;
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
}