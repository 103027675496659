/* src/pages/DashboardPage/Dashboard/ConversationHistory/ConversationHistory.module.css */
.conversationHistory {
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.header h2 {
  font-size: 1rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
}

.conversationsList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.conversationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: transparent;
  position: relative;
  border: 1px solid transparent;
}

/* Hover effect with clear visual feedback */
.conversationItem:hover {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Make hover state show menu button more prominently */
.conversationItem:hover .menuButton {
  opacity: 1;
}

.active {
  background-color: rgba(0, 168, 107, 0.15);
  border-color: rgba(0, 168, 107, 0.3);
}

.active .conversationTitle {
  color: #00a86b;
  font-weight: 600;
}

.conversationInfo {
  flex: 1;
  min-width: 0;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
}

.conversationTitle {
  font-size: 0.9rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Processing state styling */
.processing {
  cursor: default;
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.processingIndicator {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 4px;
}

.spinnerIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Menu styling */
.menuContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  opacity: 0.7;
}

.menuButton:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.9);
}

.menuButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 168, 107, 0.4);
}

.menuDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #252525;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 100;
  min-width: 160px;
  padding: 4px;
  animation: slideUp 0.2s ease;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 12px;
  background: none;
  border: none;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
}

.menuItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
}

.menuItem svg {
  font-size: 0.9rem;
}

.noConversations {
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
  padding: 20px;
}

/* For better mobile support */
@media (max-width: 768px) {
  .conversationHistory {
    padding: 12px;
  }

  .menuDropdown {
    min-width: 140px;
  }
}